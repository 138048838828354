export default function () {
  const store = useStore();

  /**
   * Creates a Intl.NumberFormatter with app defaults with fallback to store locale.
   * @returns Intl number formatter instance
   */
  const wholeNumberFormatter = (intlOptions: Intl.NumberFormatOptions = {}, locale?: string): Intl.NumberFormat => {
    return new Intl.NumberFormat(locale ?? store.state.i18n?.locale ?? undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      ...intlOptions,
    });
  };

  /**
   * Convenience method for one off unit formatting with fallback to store locale.
   * @returns {string} locale formatted amount
   */
  const wholeNumberFormat = (amount: number, intlOptions: Intl.NumberFormatOptions = {}, locale?: string): string => {
    return wholeNumberFormatter(intlOptions, locale).format(amount);
  };

  /**
   * Creates a Intl.NumberFormatter for a currency with app defaults and fallback to store currency/locale.
   * @returns Intl number formatter instance
   * @throws TypeError if store currency is undefined and valid currency not specified in intlOptions
   */
  const currencyFormatter = (intlOptions: Intl.NumberFormatOptions = {}, locale?: string): Intl.NumberFormat => {
    return new Intl.NumberFormat(locale ?? store.state.i18n?.locale ?? undefined, {
      style: "currency",
      currency: store.state.currency.code ?? undefined,
      ...intlOptions,
    });
  };

  /**
   * Convenience method for one off currency formatting with fallback to store currency/locale.
   * @returns {string} locale formatted amount
   * @throws TypeError if store currency is undefined and valid currency not specified in intlOptions
   */
  const currencyFormat = (amount: number, intlOptions: Intl.NumberFormatOptions = {}, locale?: string): string => {
    return currencyFormatter(intlOptions, locale).format(amount);
  };

  /**
   * Creates a Intl.NumberFormatter for a unit with app defaults and fallback to store locale.
   * @param - Intl unit e.g 'stone', 'pound' etc
   * @returns Intl number formatter instance
   */
  const unitFormatter = (
    unit: string,
    intlOptions: Intl.NumberFormatOptions = {},
    locale?: string
  ): Intl.NumberFormat => {
    return new Intl.NumberFormat(locale ?? store.state.i18n?.locale ?? undefined, {
      style: "unit",
      unitDisplay: "narrow",
      unit: unit,
      ...intlOptions,
    });
  };

  /**
   * Convenience method for one off unit formatting with fallback to store locale.
   * @param - Intl unit e.g 'stone', 'pound' etc
   * @returns locale formatted amount
   */
  const unitFormat = (
    amount: number,
    unit: string,
    intlOptions: Intl.NumberFormatOptions = {},
    locale?: string
  ): string => {
    return unitFormatter(unit, intlOptions, locale).format(amount);
  };

  return {
    unitFormat,
    unitFormatter,
    currencyFormatter,
    currencyFormat,
    wholeNumberFormatter,
    wholeNumberFormat,
  };
}
